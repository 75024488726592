import { useContext, useMemo } from 'react';
import { EntityTemplateFieldsContext } from './Context';
export function useCountryField() {
  const fields = useContext(EntityTemplateFieldsContext);

  return useMemo(() => {
    if (fields == null)
      return null;

    return fields.find(f => f.editor === 'COUNTRY' || f.editor === 'COUNTRY_READ_ONLY');
  }, [fields]);
}

export function useTerritorialUnitParentField(parentUnitType) {
  const fields = useContext(EntityTemplateFieldsContext);
  
  return useMemo(() => {
    if (fields == null)
      return null;

    const territorialUnitFields = fields.filter(f => f.editor === 'TERRITORIAL_UNIT');
    return territorialUnitFields.find(f => f.settings.type === parentUnitType);
  }, [fields]);
}
