import { useEffect, useMemo, useRef } from 'react';
import PropTypes from 'prop-types';
import { getIn, useFormikContext } from 'formik';
import { useCountryField, useTerritorialUnitParentField } from '../hooks';
import { FormGroup } from '../../formGroups';
import { DropdownField } from '../../fields';
import { useEffectOnChange } from 'utils/hooks';
import { connect } from 'react-redux';
import { requestTerritorialUnits } from 'behavior/entityTemplates/actions';
import { useDispatch } from 'react-redux';
import { TerritorialUnitType } from 'behavior/entityTemplates/types';
import { orderBy } from 'lodash';

const TerritorialUnitFormGroup = ({
  fieldName,
  parentUnitType,
  type,
  territorialUnits,
  validation,
  ...additionalProps
}) => {
  const { values, setFieldValue, setFieldTouched } = useFormikContext();
  const dispatch = useDispatch();
  const parentUnitField = parentUnitType === TerritorialUnitType.Country ? useCountryField() : useTerritorialUnitParentField(parentUnitType);
  const parentUnitId = parentUnitField &&
    (parentUnitField.name && getIn(values, parentUnitField.name));
  const shouldRequestTerritorialUnits = parentUnitId && !territorialUnits?.some(unit => unit.parentId === parentUnitId && unit.type === type);
  useEffect(() => {
    if (shouldRequestTerritorialUnits)
      dispatch(requestTerritorialUnits(type, parentUnitId));
  }, [shouldRequestTerritorialUnits, parentUnitId]);

  const unitsToDisplay = useMemo(() => {
    if (!parentUnitId) {
      return null;
    }
    let result = territorialUnits || [];
    result = result.filter(unit => unit.parentId === parentUnitId && unit.type === type) ?? [];
    result = orderBy(result, i => i.name, 'asc');
    return result.length
      ? result.map(({ id, name }) => ({ name, value: id }))
      : [];
  }, [shouldRequestTerritorialUnits, parentUnitId]);

  const hadUnitsToDisplayRef = useRef();

  useEffectOnChange(() => {
    const hadUnitsToDisplay = hadUnitsToDisplayRef.current;
    hadUnitsToDisplayRef.current = !!unitsToDisplay;

    if (hadUnitsToDisplay === undefined)
      return;

    const selectedUnit = getIn(values, fieldName);
    if (!selectedUnit)
      return;

    if (unitsToDisplay) {
      if (unitsToDisplay.some(unit => unit.value === selectedUnit))
        return;
    }

    setFieldValue(fieldName, '', false);
    setFieldTouched(fieldName, false, false);
  }, [unitsToDisplay]);

  if (unitsToDisplay == null || !unitsToDisplay.length)
    return null;

  // omit max length validation for dropdown
  validation = { ...validation, maxLength: undefined };
  const placeholderTextKey = `Select${type.charAt(0)}${type.toLowerCase().slice(1)}Placeholder`;
  return (
    <FormGroup
      fieldName={fieldName}
      fieldComponent={DropdownField}
      items={unitsToDisplay}
      placeholderTextKey={placeholderTextKey}
      {...additionalProps}
      validation={validation}
    />
  );
};

TerritorialUnitFormGroup.propTypes = {
  fieldName: PropTypes.string.isRequired,
  territorialUnits: PropTypes.arrayOf(PropTypes.shape({
    name: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    type: PropTypes.string.isRequired,
  })),
  type: PropTypes.string.isRequired,
  parentUnitType: PropTypes.string.isRequired,
  validation: PropTypes.object,
};

const mapStateToProps = ({ page }) => ({
  territorialUnits: page.territorialUnits,
});

export default connect(mapStateToProps)(TerritorialUnitFormGroup);

